/* admin-styles.css */

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f4f4f4;
  --text-color: #333;
  --border-color: #ddd;
  --error-color: #e74c3c;
  --success-color: #27ae60;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  padding: 20px;
}

.admin-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1, h2, h3 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input[type="text"],
input[type="password"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 16px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

.delete-button {
  background-color: var(--error-color);
}

.delete-button:hover {
  background-color: #c0392b;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

th {
  background-color: var(--primary-color);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.metadata-field {
  border: 1px solid var(--border-color);
  padding: 15px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.metadata-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Stili per react-select */
.react-select__control {
  border-color: var(--border-color) !important;
}

.react-select__option--is-selected {
  background-color: var(--primary-color) !important;
}

.react-select__option:hover {
  background-color: #e8f0fe !important;
}

/* Stili per react-quill */
.quill {
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.ql-toolbar {
  border-bottom: 1px solid var(--border-color) !important;
  background-color: #f9f9f9;
}

.ql-container {
  min-height: 200px;
}

/* Responsive design */
@media (max-width: 768px) {
  .admin-container {
    padding: 10px;
  }

  input[type="text"],
  input[type="password"],
  textarea,
  select,
  button {
    font-size: 14px;
  }
}